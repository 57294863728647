import React, { useEffect, useState } from "react";
import { RadioButton } from 'primereact/radiobutton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { Controller } from "react-hook-form";
import { Checkbox } from "primereact/checkbox";

const DoRadioButtons = (props) => {
    const [buttonValue, setButtonValue] = useState('');
    const [selectedLabel, setSelectedLabel] = useState('');
    const [checkValue, setCheckValue] = useState([]);
    const id = props.id ? props.id : props.name;
    let field = props.field;
    let value = props.defVal;

    const options = [
        { label: 'Delete', value: 'Delete' },
        { label: 'Approve/Reject', value: 'Approve/Reject' }
    ];

    useEffect(() => {
        if (props.type === 'Roles') {
            onRadioChange(field.value, props.name)
        }
        if (props?.getValues && props.getValues(props.name + 'Actions')) {
            setCheckValue(props.getValues(props.name + 'Actions'))
        }
    }, [field.value]);

    const onCheckBoxChange = (e, field) => {
        const value = e.value;
        const isChecked = e.checked;

        let updatedValues;
        if (isChecked) {
            updatedValues = [...checkValue, value];
        } else {
            updatedValues = checkValue.filter(item => item !== value);
        }
        field.onChange(updatedValues)
        setCheckValue(updatedValues);
    }

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    let onRadioChange = (e, label) => {
        setSelectedLabel(label + 'Actions')
        setButtonValue(e);
        if (e !== 'Edit') {
            if (props.setValue) {
                props.setValue(label + 'Actions', [])
            }
        }
    }

    return (
        <div className="flex flex-column ">
            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{props.label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <div className={props.label === 'Work Type' ? "custom-radio-buttons" : "flex align-items-center "}>
                {props.options ? props.options.map((option) => {
                    return <div key={option.value}>
                        <RadioButton {...field} inputId={option.label} name={props.name} inputRef={field.ref} value={option.value}
                            checked={typeof field.value === 'boolean' ? String(field.value) === option.value : field.value === option.value}
                        />
                        <label htmlFor={option.label} className="ms-1 me-4">
                            {option.label}
                        </label>
                    </div>
                }) :

                    <>
                        <RadioButton {...field} inputRef={field.ref} value={props.value} checked={field.value === value} />
                        <label className="ms-1 me-4">
                            {props.label}
                        </label>
                    </>}
               
            </div>
            <div>
                {(buttonValue && buttonValue == 'Edit') &&
                    <div className="flex align-items-center" style={{ marginLeft: '175px' }}>
                        <Controller
                            name={selectedLabel}
                            control={props.control}
                            render={({ field }) => (
                                <>
                                    {options ? options.map((option) => (
                                        <div key={option.label}>
                                            <Checkbox
                                                inputRef={field.ref}
                                                inputId={field.name}
                                                value={option.value}
                                                onChange={(e) => onCheckBoxChange(e, field)}
                                                checked={checkValue.includes(option.value)}
                                            />
                                            <label htmlFor={option.label} className="me-2 mt-4 ms-1">
                                                {option.label}
                                            </label>
                                        </div>
                                    )) : null}
                                </>
                            )}
                        />
                    </div>
                }
            </div>
            <small className="text-danger " style={{ marginTop: "5px" }}>{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        </div>
    )
}

export default DoRadioButtons;