import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import ApproveOrReject from "../../CommonModals/ApproveOrReject";
// config file

const Leaves = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Leaves");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const customFun = (rowData) => {
    let arr = [];

    if (rowData && rowData.status) {
      if (rowData.status === "Pending") {
        arr.push('edit', 'delete');
      } else if (rowData.status === 'Approved' || rowData.status === 'Rejected') {
        if (loginRole.roleType === 'Admin') {
          arr.push('delete');
        }
      }
    }
    return arr
  }

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employeeName",
        fieldName: "employeeName",
        type: "relateAutoComplete",
        placeholder: "Employee Name",
        value: "",
        label: "Employee",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Employee",
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=name=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "employeeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        displayFields: ["FirstName"],
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "center",
        show: true,
        fieldClass: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        field: "employeeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Created By",
        derivedValue: "createdBy=createdBy=createdBy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "text",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Updated By",
        derivedValue: "updated By=updatedBy=updatedBy=updatedBy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        fieldType: "text",
        isAddToIndex: "",
        textAlign: "center",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Applied Date",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Applied Date",
        derivedValue:
          "created=created=created=created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        customFun: customFun,
        mobile: true,
        tDisplay: true,
      },
      {
        name: "leaveType",
        fieldName: "leaveType",
        type: "dropDown",
        placeholder: "Leave Type",
        value: "",
        label: "Leave Type",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Leave Type",
        derivedValue:
          "leaveType=leaveType=leaveType=leaveType=leaveType=leaveType=LeaveType=LeaveType=LeaveType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "secondary" },
        ],
        defaultValues: ['Sick Leave', 'Casual Leave'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "leaveType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "leaveDay",
        fieldName: "leaveDay",
        type: "dropDown",
        placeholder: "Leave Day",
        value: "",
        label: "Leave Day",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Leave Day",
        derivedValue:
          "leaveDay=leaveDay=leaveDay=leaveDay=LeaveDay=LeaveDay=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveDay",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Half Day", value: "Half Day", color: "warning" },
          { label: "Full Day", value: "Full Day", color: "success" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Half Day", value: "Half Day", color: "warning" },
          { label: "Full Day", value: "Full Day", color: "success" },
        ],
        defaultValues: ['Half Day', 'Full Day'],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "center",
        show: true,
        field: "leaveDay",
        showOrHideFields: [],
        label0: "Half Day",
        color0: "warning",
        value0: "Half Day",
        label1: "Full Day",
        color1: "success",
        value1: "Full Day",
        fieldName: "leaveDay",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reason",
        fieldName: "reason",
        type: "textarea",
        placeholder: "Reason",
        value: "",
        label: "Reason",
        width: "200px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Reason",
        derivedValue: "reason=reason=reason=reason=Reason=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reason",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        style: { width: '250px', textOverflow: "ellipsis" },
        show: true,
        field: "reason",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=status=status=status=status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Approved", value: "Approved", color: "success" },
          { label: "Pending", value: "Pending", color: "danger", },
          { label: "Rejected", value: "Rejected", color: "secondary", },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Approved", value: "Approved" },
          { label: "Pending", value: "Pending", },
          { label: "Rejected", value: "Rejected", },],
        defaultValues: ['Approved', 'Pending', 'Rejected'],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfDays",
        fieldName: "numberOfDays",
        type: "number",
        placeholder: "numberOfDays",
        value: "",
        label: "No Of Days",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "No Of Days",
        derivedValue:
          "numberOfDays=numberOfDays=numberOfDays=numberOfDays=numberOfDays=numberOfDays=noOfDays=NoOfDays=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "numberOfDays",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromDate",
        fieldName: "fromDate",
        type: "date",
        placeholder: "From Date",
        value: "",
        label: "From Date",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "From Date",
        derivedValue: "fromDate=fromDate=fromDate=fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "fromDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toDate",
        fieldName: "toDate",
        type: "date",
        placeholder: "To Date",
        value: "",
        label: "To Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "To Date",
        derivedValue: "toDate=toDate=toDate=toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "toDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "rejectReason",
        fieldName: "rejectReason",
        type: "text",
        placeholder: "RejectReason",
        value: "",
        label: "Reject Reason",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Reject Reason",
        derivedValue:
          "rejectReason=rejectReason=rejectReason=rejectReason=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "rejectReason",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "rejectReason",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "halfDayType",
        fieldName: "halfDayType",
        type: "text",
        placeholder: "HalfDay Type",
        value: "",
        label: "HalfDay Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "HalfDay Type",
        derivedValue:
          "halfDayType=halfDayType=halfDayType=halfDayType=halfDayType=halfDayType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "halfDayType",
        options: [
          { label: "First Half", value: "First half", color: "warning" },
          { label: "Second Half", value: "Second half", color: "success" },
        ],
        fieldType: "dropDown",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "halfDayType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        fieldName: "companyEmpId",
        type: "text",
        placeholder: "EmployeeId",
        value: "",
        label: "Employee Id",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 29,
        header: "Employee Id",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=employeeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reportingTo",
        fieldName: "reportingTo",
        type: "text",
        placeholder: "ReportingTo",
        value: "",
        label: "Reporting To",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Reporting To",
        derivedValue:
          "reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=reportingToName=reportingToName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        searchApi: "employees",
        textAlign: "center",
        show: true,
        field: "reportingToName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "employeeName",
        fieldName: "employeeName",
        type: "relateAutoComplete",
        placeholder: "Employee",
        value: loginRole && loginRole.displayName ? loginRole : '',
        label: "Employee",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "employeeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: true,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        displayFields: ["FirstName"],
        controllerName: "employee",
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        isShowItemTemplate: true,
        templateField: 'employeeId'
      },
      {
        name: "leaveType",
        fieldName: "leaveType",
        type: "dropDown",
        placeholder: "Leave Type",
        value: "",
        label: "Leave Type",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue:
          "leaveType=leaveType=leaveType=leaveType=leaveType=leaveType=LeaveType=LeaveType=LeaveType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "secondary" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "leaveDay",
        fieldName: "leaveDay",
        type: "dropDown",
        placeholder: "Leave Day",
        value: "",
        label: "Leave Day",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue:
          "leaveDay=leaveDay=leaveDay=leaveDay=LeaveDay=LeaveDay=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveDay",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Half Day", value: "Half Day", color: "warning" },
          { label: "Full Day", value: "Full Day", color: "success" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Half Day", value: "Half Day", color: "warning" },
          { label: "Full Day", value: "Full Day", color: "success" },
        ],
        // dependent: [],
        show: true,
        showOrHideFields: [],
        label0: "Half Day",
        color0: "warning",
        value0: "Half Day",
        label1: "Full Day",
        color1: "success",
        value1: "Full Day",
        fieldName: "leaveDay",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{ "Half Day": ["halfDayType"] }],
      },
      {
        name: "halfDayType",
        fieldName: "halfDayType",
        type: "dropDown",
        placeholder: "Half Day Type",
        value: "",
        label: "Half Day Type",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue:
          "halfDayType=halfDayType=halfDayType=halfDayType=halfDayType=halfDayType=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "halfDayType",
        options: [
          { label: "First Half", value: "First half", color: "warning" },
          { label: "Second Half", value: "Second half", color: "success" },
        ],
        fieldType: "dropDown",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        filterElement: [
          { label: "First Half", value: "First half", color: "warning" },
          { label: "Second Half", value: "Second half", color: "success" },
        ],
        // dependent: [],
        show: true,
        showOrHideFields: [],
        // label0: "First half",
        // dependentField0: "",
        // color0: "",
        // value0: "First half",
        // label1: "Second Half",
        // dependentField1: "",
        // color1: "",
        // value1: "Second Half",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "reason",
        fieldName: "reason",
        type: "textarea",
        placeholder: "Reason",
        value: "",
        label: "Reason",
        width: "200px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "reason=reason=reason=reason=Reason=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reason",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "fromDate",
        fieldName: "fromDate",
        type: "date",
        placeholder: "From Date",
        value: "",
        label: "From Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "fromDate=fromDate=fromDate=fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "toDate",
        fieldName: "toDate",
        type: "date",
        placeholder: "To Date",
        value: "",
        label: "To Date",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "toDate=toDate=toDate=toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employeeName",
        fieldName: "employeeName",
        type: "relateAutoComplete",
        placeholder: "Employee Name",
        value: "",
        label: "Employee",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Employee",
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=name=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "employeeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        displayFields: ["FirstName"],
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "center",
        show: true,
        field: "employeeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Created By",
        derivedValue: "createdBy=createdBy=createdBy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Updated By",
        derivedValue: "updated By=updatedBy=updatedBy=updatedBy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        fieldType: "text",
        isAddToIndex: "",
        textAlign: "center",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Applied Date",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Applied Date",
        derivedValue:
          "created=created=created=created=created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "leaveType",
        fieldName: "leaveType",
        type: "dropDown",
        placeholder: "Leave Type",
        value: "",
        label: "Leave Type",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Leave Type",
        derivedValue:
          "leaveType=leaveType=leaveType=leaveType=leaveType=leaveType=LeaveType=LeaveType=LeaveType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "secondary" },
        ],
        defaultValues: ['Sick Leave', 'Casual Leave'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "leaveType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "leaveDay",
        fieldName: "leaveDay",
        type: "dropDown",
        placeholder: "Leave Day",
        value: "",
        label: "Leave Day",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Leave Day",
        derivedValue:
          "leaveDay=leaveDay=leaveDay=leaveDay=LeaveDay=LeaveDay=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveDay",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Half Day", value: "Half Day", color: "warning" },
          { label: "Full Day", value: "Full Day", color: "success" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Half Day", value: "Half Day", color: "warning" },
          { label: "Full Day", value: "Full Day", color: "success" },
        ],
        defaultValues: ['Half Day', 'Full Day'],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "center",
        show: true,
        field: "leaveDay",
        showOrHideFields: [],
        label0: "Half Day",
        color0: "warning",
        value0: "Half Day",
        label1: "Full Day",
        color1: "success",
        value1: "Full Day",
        fieldName: "leaveDay",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reason",
        fieldName: "reason",
        type: "textarea",
        placeholder: "Reason",
        value: "",
        label: "Reason",
        width: "200px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Reason",
        derivedValue: "reason=reason=reason=reason=Reason=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reason",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        style: { width: '250px', textOverflow: "ellipsis" },
        show: true,
        field: "reason",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=status=status=status=status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Approved", value: "Approved", color: "success" },
          { label: "Pending", value: "Pending", color: "danger", },
          { label: "Rejected", value: "Rejected", color: "secondary", },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Approved", value: "Approved" },
          { label: "Pending", value: "Pending", },
          { label: "Rejected", value: "Rejected", },],
        defaultValues: ['Approved', 'Pending', 'Rejected'],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfDays",
        fieldName: "numberOfDays",
        type: "number",
        placeholder: "numberOfDays",
        value: "",
        label: "No Of Days",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "No Of Days",
        derivedValue:
          "numberOfDays=numberOfDays=numberOfDays=numberOfDays=numberOfDays=numberOfDays=noOfDays=NoOfDays=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "numberOfDays",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromDate",
        fieldName: "fromDate",
        type: "date",
        placeholder: "From Date",
        value: "",
        label: "From Date",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "From Date",
        derivedValue: "fromDate=fromDate=fromDate=fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "fromDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toDate",
        fieldName: "toDate",
        type: "date",
        placeholder: "To Date",
        value: "",
        label: "To Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "To Date",
        derivedValue: "toDate=toDate=toDate=toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "toDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "rejectReason",
        fieldName: "rejectReason",
        type: "text",
        placeholder: "RejectReason",
        value: "",
        label: "Reject Reason",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Reject Reason",
        derivedValue:
          "rejectReason=rejectReason=rejectReason=rejectReason=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "rejectReason",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "rejectReason",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "halfDayType",
        fieldName: "halfDayType",
        type: "text",
        placeholder: "HalfDay Type",
        value: "",
        label: "HalfDay Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "HalfDay Type",
        derivedValue:
          "halfDayType=halfDayType=halfDayType=halfDayType=halfDayType=halfDayType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "halfDayType",
        options: [
          { label: "First Half", value: "First half", color: "warning" },
          { label: "Second Half", value: "Second half", color: "success" },
        ],
        fieldType: "dropDown",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "halfDayType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        fieldName: "companyEmpId",
        type: "text",
        placeholder: "EmployeeId",
        value: "",
        label: "Employee Id",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 29,
        header: "Employee Id",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=employeeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reportingTo",
        fieldName: "reportingTo",
        type: "text",
        placeholder: "ReportingTo",
        value: "",
        label: "Reporting To",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Reporting To",
        derivedValue:
          "reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=reportingToName=reportingToName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "center",
        show: true,
        field: "reportingToName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const acceptsubmit1 = (item) => {
    let x = ["leaveType"];
    let objleaves = {};

    objleaves["_id"] = item[0]["_id"];
    for (let x2 of x) {
      objleaves[x2] = item[0][x2];
    }
    let formFields = getFormFields();

    formFields = formFields.filter((y) => x.includes(y.name));

    setopenNewUserModal(true);
    setItem(objleaves);
    setNewFormField(formFields);
  };
  const rejectsubmit1 = (item) => {
    let x = ["leaveType"];
    let objleaves = {};

    objleaves["_id"] = item[0]["_id"];
    for (let x2 of x) {
      objleaves[x2] = item[0][x2];
    }
    let formFields = getFormFields();

    formFields = formFields.filter((y) => x.includes(y.name));

    setopenNewUserModal(true);
    setItem(objleaves);
    setNewFormField(formFields);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.leaves}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer();
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  //Displaying custom action icons approve and reject based on conditions
  const customActionButton = (item, getData, filters) => {
    return (
      <>
        {item.status == 'Pending' && (loginRole.roleType === 'Admin' || item.approved === true) ?
          <ApproveOrReject
            item={item}
            getDataFromServer={getData}
            filterCriteria={filters}
            type={'leaves'}
          />
          : null}
      </>
    )
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },

    {
      name: "Approved",
      action: saveDataToServer,
      options: [
        {
          label: "Approved",
          field: "status",
          value: "Approved",
          show: rolePermission?.actions?.includes("Update") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Pending",
      action: saveDataToServer,
      options: [
        {
          label: "Pending",
          field: "status",
          value: "Pending",
          show: rolePermission?.actions?.includes("Update") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Rejected",
      action: saveDataToServer,
      options: [
        {
          label: "Rejected",
          field: "status",
          value: "Rejected",
          show: rolePermission?.actions?.includes("Update") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "First half",
      action: saveDataToServer,
      options: [
        {
          label: "First half",
          field: "halfDayType",
          value: "First half",
          show: rolePermission?.actions?.includes("Update") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Second Half",
      action: saveDataToServer,
      options: [
        {
          label: "Second Half",
          field: "halfDayType",
          value: "Second Half",
          show: rolePermission?.actions?.includes("Update") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "accept",
      action: acceptsubmit1,
      options: [
        {
          label: "accept",
          value: "accept",
          show: rolePermission?.actions?.includes("Update") ? true : false,
          multiple: false,
        },
      ],
    },

    {
      name: "reject",
      action: rejectsubmit1,
      options: [
        {
          label: "reject",
          value: "reject",
          show: rolePermission?.actions?.includes("Update") ? true : false,
          multiple: false,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          // globalSearch={'Display Name/Email'}
          // type='Leaves'
          // apiUrl={apiCalls.Leaves}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.leaves}
          globalSearch={
            "employeeName/created/updated/leaveType/leaveDay/reason/status/numberOfDays/fromDate/toDate/rejectReason/halfDayType/reportingTo/companyEmpId/createdBy/updated By"
          }
          displayName="Leaves"
          type="Leaves"
          routeTo={apiCalls.leaves}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.leaves?.toLowerCase()}
          apiUrl={apiCalls.leaves}
          selectedId={params.id}
          displayActionsInTable={"actions"}
          customActionButton={customActionButton}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="leaves"
          apiUrl={apiCalls.leaves}
        />
      ) : null}
    </span>
  );
};

export default Leaves;
